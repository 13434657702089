<template>
    <el-drawer title="商家详情" :visible.sync="drawer" direction="rtl" size="60%" :before-close="handleClose">
        <div class="drawer">
            <div class="draimg">
                <div class="imgbox">
                    <div class="imgname">身份证扫描件:</div>
                    <div class="img">
                        <img :src="businessdetail.idCardImgFront" alt="">
                        <img :src="businessdetail.idCardImgReverse" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="imgname">营业执照:</div>
                    <div class="img">
                        <img :src="businessdetail.logoUrl" alt="">
                    </div>
                </div>
                <div class="imgbox">
                    <div class="imgname">资历证书:</div>
                    <div class="img">
                        <img :src="businessdetail.qualifications" alt="">

                    </div>
                </div>
                <div class="imgbox">
                    <div class="imgname">经营场景:</div>
                    <div class="img">
                        <img :src="businessdetail.qualifications" alt="">
                        <img :src="businessdetail.qualifications" alt="">
                    </div>
                </div>

            </div>
            <div class="bl">
                <el-form ref="form" :model="form" label-width="50px">
                    <div style="display: flex;">
                        <div class="bl-title" style="margin-top: 5px;">积分使用比例：</div>
                        <div class="bls">
                            <el-row>
                                <el-col span="8">
                                    <el-form-item label="消费">
                                        <el-input v-model="form.name">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col span="8">
                                    <el-form-item label="获得">
                                        <el-input v-model="form.name">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div class="bl-title" style="margin-top: 5px;">抽佣比例：</div>
                        <div class="bls">
                            <el-row>
                                <el-col span="8">
                                    <el-form-item label="消费">
                                        <el-input v-model="form.name">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col span="8">
                                    <el-form-item label="获得">
                                        <el-input v-model="form.name">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>
                    <div style="display: flex;">
                        <div class="bl-name" style="margin-top: 5px;">账期设置：</div>
                        <div class="bls">
                            <el-row>
                                <el-col span="8">
                                    <el-form-item label="消费">
                                        <el-input v-model="form.name">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col span="8">
                                    <el-form-item label="获得">
                                        <el-input v-model="form.name">
                                            <template slot="append">元</template>
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                    </div>

                    <el-form-item label="活动区域">
                        <el-select v-model="form.region" placeholder="请选择活动区域">
                            <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="活动时间">
                        <el-col :span="11">
                            <el-date-picker type="date" placeholder="选择日期" v-model="form.date1"
                                style="width: 100%;"></el-date-picker>
                        </el-col>
                        <el-col class="line" :span="2">-</el-col>
                        <el-col :span="11">
                            <el-time-picker placeholder="选择时间" v-model="form.date2"
                                style="width: 100%;"></el-time-picker>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="即时配送">
                        <el-switch v-model="form.delivery"></el-switch>
                    </el-form-item>
                    <el-form-item label="活动性质">
                        <el-checkbox-group v-model="form.type">
                            <el-checkbox label="美食/餐厅线上活动" name="type"></el-checkbox>
                            <el-checkbox label="地推活动" name="type"></el-checkbox>
                            <el-checkbox label="线下主题活动" name="type"></el-checkbox>
                            <el-checkbox label="单纯品牌曝光" name="type"></el-checkbox>
                        </el-checkbox-group>
                    </el-form-item>
                    <el-form-item label="特殊资源">
                        <el-radio-group v-model="form.resource">
                            <el-radio label="线上品牌商赞助"></el-radio>
                            <el-radio label="线下场地免费"></el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="活动形式">
                        <el-input type="textarea" v-model="form.desc"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">立即创建</el-button>
                        <el-button>取消</el-button>
                    </el-form-item>
                </el-form>
                <div class="bl-li">
                    <div class="bls">
                        <el-row>

                        </el-row>
                        <div style="width: 50px;">消费</div>
                        <!-- <input v-model="detaillist.integralRatioGet" type="text">
                        <div class="text-box">元</div> -->
                        <el-input size="medium" v-model="detaillist.integralRatioUse" type="text">
                            <template slot="append">元</template>
                        </el-input>
                    </div>
                    <div class="bls">
                        <div>获得</div>
                        <input v-model="detaillist.integralRatioGet" type="text">
                        <div class="text-box">积分</div>
                    </div>
                </div>
                <div class="bl-li">
                    <div class="bl-name">抽佣比例：</div>
                    <div class="bls">
                        <div>外卖</div>
                        <input v-model="detaillist.takeawayRake" type="text">
                        <div class="text-box">%</div>
                    </div>
                    <div class="bls">
                        <div>团购</div>
                        <input v-model="detaillist.buyRake" type="text">
                        <div class="text-box">%</div>
                    </div>
                    <div class="bls">
                        <div>到店扫码</div>
                        <input v-model="detaillist.yardRake" type="text">
                        <div class="text-box">%</div>
                    </div>
                </div>
                <div class="bl-li">
                    <div class="bl-name">账期设置：</div>
                    <div class="bls">
                        <div>账期</div>
                        <input v-model="detaillist.accountTerm" type="text">
                        <div class="text-box">天</div>
                    </div>

                </div>
            </div>
            <div
                style="display: flex;margin-bottom: 10px; align-items: center;justify-content: space-between;padding-right: 100px;">
                <div class="qx">
                    <el-switch v-model="detaillist.integralDeduceAuthority" inactive-text="积分抵扣权限">
                    </el-switch>
                    <el-switch v-model="detaillist.platformDeliveryAuthority" inactive-text="平台配送权限">
                    </el-switch>
                    <el-switch v-model="detaillist.couponVerificateAuthority" inactive-text="优惠券核销权限">
                    </el-switch>
                    <el-switch v-model="detaillist.selfDeliveryAuthority" inactive-text="商家自配送权限">
                    </el-switch>
                </div>
                <div class="but" style="margin-top: 20px;">
                    <el-button type="danger" @click="drawer = false">取消 </el-button>
                    <el-button type="primary" @click="detailok()">保存</el-button>
                </div>
            </div>
            <div class="drwdetail">
                <div style="padding-left: 20px;">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="余额存入记录" name="first">
                            <div>
                                <el-table :data="yedetail" style="width: 100%" height="calc(100vh - 380px)" border
                                    :header-cell-style="{
                                        'background-color': '#f2f2f2',
                                        'text-align': 'center',
                                        border: '1px solid #ddd',
                                    }">
                                    <el-table-column prop="name" label="交易说明" align="center">
                                    </el-table-column>
                                    <el-table-column prop="name" label="存入时间" align="center">
                                    </el-table-column>
                                    <el-table-column prop="name" label="存入金额" align="center">
                                    </el-table-column>

                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="提现明细" name="second">
                            <div>
                                <el-table :data="txdetail" style="width: 100%" height="calc(100vh - 580px)" border
                                    :header-cell-style="{
                                        'background-color': '#f2f2f2',
                                        'text-align': 'center',
                                        border: '1px solid #ddd',
                                    }">
                                    <el-table-column prop="amount" label="提现金额" align="center">
                                    </el-table-column>
                                    <el-table-column prop="creatTime" label="提现日期" align="center">
                                    </el-table-column>
                                    <el-table-column prop="payoutsCode" label="提现到账银行卡" align="center">
                                    </el-table-column>
                                    <el-table-column prop="payoutsStatus" label="审核状态" align="center">
                                    </el-table-column>
                                    <el-table-column prop="creatTime" label="提现审核通过日期" align="center">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="评论明细" name="third">
                            <div>
                                <el-table :data="pjdetail" style="width: 100%" height="calc(100vh - 580px)" border
                                    :header-cell-style="{
                                        'background-color': '#f2f2f2',
                                        'text-align': 'center',
                                        border: '1px solid #ddd',
                                    }">
                                    <el-table-column prop="userCode" label="评论用户ID" align="center">
                                    </el-table-column>
                                    <el-table-column prop="orderId" label="关联订单" align="center">
                                    </el-table-column>
                                    <el-table-column prop="content" label="评论内容" align="center">
                                    </el-table-column>
                                    <el-table-column prop="appraiseImg" label="评论图片视频" align="center">
                                    </el-table-column>
                                    <el-table-column prop="appraise" label="评分" align="center">
                                    </el-table-column>
                                    <el-table-column prop="creatTime" label="评论时间" align="center">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="店铺明细" name="fourth">
                            <div>
                                <el-table :data="dpdetail" style="width: 100%" height="calc(100vh - 580px)" border
                                    :header-cell-style="{
                                        'background-color': '#f2f2f2',
                                        'text-align': 'center',
                                        border: '1px solid #ddd',
                                    }">
                                    <el-table-column prop="name" label="店铺头像" align="center">
                                    </el-table-column>
                                    <el-table-column prop="店铺名称" label="邀请人手机号" align="center">
                                    </el-table-column>
                                    <el-table-column prop="name" label="店铺地址" align="center">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="合同管理" name="five">
                            <div>
                                <div
                                    style="height: 61px;line-height: 61px; padding: 2px 2px 2px 2px;border-radius: 5px;background-color: rgba(223, 243, 255, 0.56);">
                                    已签约 2024-10-10</div>
                                <div>
                                    <img style="width: 75px;height: 75px;" src="@/assets/images/ht.png" alt="">
                                    <div>商家入驻合同.docx</div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
        </div>
    </el-drawer>

</template>

<script>
export default {
    data() {
        return {
            drawer: false,
            businessdetail: {},
            detaillist: [],
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            dialogTitle: '新增商家收款账户',
            row: {}
        };
    },
    created() {

    },
    methods: {
        init(row = {}) {
            this.drawer = true
            this.row = row
            console.log(row, '2222222222222222')
        },
        handleClose() {
            this.drawer = false
        },
        getTableData() {
            this.row.id = id
            var that = this;
            that.$http.post("/business/queryBusinessDetail", {
                id: id
            }).then(function (response) {
                console.log(response.data.data);
                if (response.data.code == 200) {
                    that.businessdetail = response.data.data
                    that.detaillist = response.data.data
                }
            });
        },
    }
};
</script>
<style lang="less" scoped>
.drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 30px;
        box-sizing: border-box;

        div {
            width: 30%;
            color: #847575;
            margin-bottom: 30px;

            span {
                color: #000;
            }
        }
    }

    .draimg {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        padding: 0 30px;

        .imgbox {
            width: 30%;
            display: flex;

            .imgname {
                color: #000;
            }

            .img {
                margin-top: 40px;
                display: flex;

                img {
                    width: 89px;
                    height: 65px;
                }
            }

        }
    }

    .bl {
        padding: 0 30px;

        .bl-title {
            width: 120px;
            display: flex;
            justify-content: flex-end;
        }

        .bl-li {
            display: flex;
            align-items: center;
            margin-top: 15px;

            .bls {
                display: flex;
                align-items: center;
                margin-left: 15px;

                input {
                    width: 100px;
                    height: 32px;
                    text-align: center
                }

                .text-box {
                    width: 38px;
                    height: 38px;
                    background-color: rgba(230, 230, 230, 0.55);
                    text-align: center;
                    line-height: 38px;
                }
            }
        }
    }

    .qx {
        padding: 0 30px;
        margin-top: 30px;
    }
}
</style>