<template>
  <div class="box">
    <div class="search">
      <el-form label-width="120px" label-height="60px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="商家账号：" prop="businessStatus">
              <el-input
                v-model="queryInfo.condition.businessAccount"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商家名称：" prop="businessStatus">
              <el-input
                v-model="queryInfo.condition.businessName"
                placeholder="请输入"
                clearable
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" style="display: flex; justify-content: flex-end">
            <el-button @click="reset" icon="el-icon-refresh-left"
              >重置</el-button
            >
            <el-button type="primary" @click="search" icon="el-icon-search"
              >搜索</el-button
            >
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tab">
      <div class="add">
        <el-button type="primary" @click="added">导出</el-button>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        show-summary
        height="calc(100vh - 380px)"
        border
        :header-cell-style="{
          'background-color': '#f2f2f2',
          'text-align': 'center',
          border: '1px solid #ddd',
        }"
      >
        <el-table-column prop="businessName" label="商家名称" align="center">
        </el-table-column>
        <el-table-column
          prop="businessAccount"
          label="商家账号"
          width="100"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="allowPayoutsNum"
          label="可提现金额"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="totalNum" label="账期金额" align="center">
        </el-table-column>
        <el-table-column prop="payoutsNum" label="提现中" align="center">
        </el-table-column>
        <el-table-column
          prop="payoutsTotalNum"
          label="已提现金额"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop=" "
          label="操作"
          width="200"
          fixed="right"
          align="center"
        >
          <template slot-scope="scope">
            <el-button type="text" @click="openAdjustment(scope.row.id)"
              >调整余额</el-button
            >
            <el-button type="text" @click="openDetail(scope.row)"
              >详情</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="queryInfo.currPage"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>

    <!-- 详情弹窗 -->

    <el-dialog
      title="商家余额调整"
      :visible.sync="showDialog"
      width="600px"
      height="1000px"
    >
      <el-form
        :model="form"
        style="padding: 0px 30px 0 10px"
        label-width="120px"
        class="demo-form"
      >
        <el-form-item label="余额调整" prop="name">
          <el-input v-model="form.orderSq"></el-input>
        </el-form-item>
      </el-form>
      <div
        class="but"
        style="display: flex; justify-content: center; margin-top: 20px"
      >
        <el-button size="medium" @click="showDialog = false">取消 </el-button>
        <el-button size="medium" type="primary" @click="bc()">保存 </el-button>
      </div>
    </el-dialog>
    <detail ref="detailRef"></detail>
  </div>
</template>

<script>
import detail from "./merchantwallet/merchantwalletDetail.vue"; // 余额调整
export default {
  components: { detail },
  data() {
    return {
      showDialog: false,
      drawer: false,

      tableData: [],
      total: 0,
      queryInfo: {
        pageSize: 50,
        pageCount: 1,
        currPage: 1,
        condition: {
          businessName: "",
          businessAccount: "",
        },
      },
      valuechange: "",
      form: {},
      tapshow: 0,
      options: [
        {
          value: "1",
          label: "店长",
        },
        {
          value: "2",
          label: "员工",
        },
      ],
      radio: "1",
      from: {},
      businessdetail: {},
      detaillist: {},
    };
  },
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    change() {
      console.log(this.valuechange);
      this.from.startDate = this.newDate(this.valuechange[0]);
      this.from.endDate = this.newDate(this.valuechange[1]);
    },
    newDate(time) {
      var date = new Date(time);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? "0" + m : m;
      var d = date.getDate();
      d = d < 10 ? "0" + d : d;
      var h = date.getHours();
      h = h < 10 ? "0" + h : h;
      var minute = date.getMinutes();
      minute = minute < 10 ? "0" + minute : minute;
      var s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "-" + m + "-" + d + " " + h + ":" + minute + ":" + s;
    },

    // 调整余额-打开弹窗
    openAdjustment() {
      this.showDialog = true;
    },
    //获取数据
    getList() {
      var that = this;
      that.$http
        .post("/business/list", that.queryInfo)
        .then(function (response) {
          console.log(response.data.data, "response.data.data");
          if (response.data.code == 200) {
            that.total = response.data.data.count;
            that.queryInfo.currPage = response.data.data.currPage;
            that.tableData = response.data.data.data;
          }
        });
    },
    // 修改页数大小
    handleSizeChange(val) {
      this.queryInfo.pageSize = val;
      this.getList();
    },

    // 获取当前页面
    handleCurrentChange(val) {
      this.queryInfo.currPage = val;
      this.getList();
    },
    search() {
      this.getList();
    },
    reset() {
      this.form = {};
      this.queryInfo.condition = {};
      this.getList();
    },
    openDetail(row) {
      this.$refs.detailRef.init(row);
    },
    added() {
      // this.showDialog = true;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  .search {
    background-color: #fff;
    box-sizing: border-box;
    padding: 20px 20px 10px 20px;

    .search-top {
      height: 66px;
      padding: 2px 2px 2px 2px;
      background-color: rgba(30, 152, 215, 0.14);

      display: flex;
      align-items: center;
      margin-bottom: 50px;
      cursor: pointer;

      div {
        width: 150px;
        text-align: center;
      }

      .active {
        border-bottom: 2px solid red;
      }
    }
  }

  .finance {
    height: 132px;
    padding: 2px 2px 2px 2px;
    border-radius: 5px;
    background-color: #ffffff;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-size: 16px;
    justify-content: space-around;

    .fin-box {
      display: flex;

      .fin-li {
        margin-left: 15px;
      }
    }
  }

  .tab {
    background-color: #fff;
    margin-top: 20px;
    padding: 20px;
  }

  .dia {
    .but {
      display: flex;
      justify-content: space-between;
      padding: 30px 200px;
    }

    .diali {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .liname {
        width: 100px;
      }

      input {
        height: 30px;
      }
    }
  }

  .drawer {
    height: 900px;
    overflow-y: scroll;

    .drauser {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;
      box-sizing: border-box;

      div {
        width: 30%;
        color: #847575;
        margin-bottom: 30px;

        span {
          color: #000;
        }
      }
    }

    .draimg {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding: 0 30px;

      .imgbox {
        width: 30%;
        display: flex;

        .imgname {
          color: #000;
        }

        .img {
          margin-top: 40px;
          display: flex;

          img {
            width: 89px;
            height: 65px;
          }
        }
      }
    }

    .bl {
      padding: 0 30px;

      .bl-li {
        display: flex;
        align-items: center;
        margin-top: 15px;

        .bls {
          display: flex;
          align-items: center;
          margin-left: 15px;

          input {
            width: 100px;
            height: 32px;
            text-align: center;
          }

          .text-box {
            width: 38px;
            height: 38px;
            background-color: rgba(230, 230, 230, 0.55);
            text-align: center;
            line-height: 38px;
          }
        }
      }
    }

    .qx {
      padding: 0 30px;
      margin-top: 30px;
    }
  }
}
</style>
